export const config = {
  events: [
    {
      title: "Teste",
      titleExtended: "Teste",
      extendedProps: {
        hasContent: true,
        hasFooter: true,
        bgColor: "#953D2A",
        headerTextColor: "#ffffff",
        footerTextColor: "#ffffff",
        showDuration: true,
      }
    },
    {
      title: "RET",
      titleExtended: "RET (Retorno)",
      extendedProps: {
        hasContent: true,
        hasFooter: true,
        bgColor: "black",
        headerTextColor: "#ffffff",
        footerTextColor: "#ffffff",
        showDuration: true,
      }
    },
    {
      title: "AL",
      titleExtended: "AL (Alinhamento)",
      extendedProps: {
        hasContent: true,
        hasFooter: true,
        bgColor: "orange",
        headerTextColor: "#ffffff",
        footerTextColor: "#ffffff",
        showDuration: true,
      }
    },
    {
      title: "DIAG",
      titleExtended: "DIAG (Diagnóstico)",
      extendedProps: {
        hasContent: true,
        hasFooter: true,
        bgColor: "#EE302D",
        headerTextColor: "#ffffff",
        footerTextColor: "#ffffff",
        showDuration: true,
      }
    },
    {
      title: "QS",
      titleExtended: "QS (Quick Service)",
      extendedProps: {
        hasContent: true,
        hasFooter: true,
        bgColor: "#1F3059",
        headerTextColor: "#ffffff",
        footerTextColor: "#ffffff",
        showDuration: true,
      }
    },
    {
      title: "MP",
      titleExtended: "MP (Manutenção Periódica)",
      extendedProps: {
        hasContent: true,
        hasFooter: true,
        bgColor: "#808080",
        headerTextColor: "#ffffff",
        footerTextColor: "#ffffff",
        showDuration: true,
      }
    },
    {
      title: "RG",
      titleExtended: "RG (Reparo Geral)",
      extendedProps: {
        hasContent: true,
        hasFooter: true,
        bgColor: "#41B64B",
        headerTextColor: "#000000",
        footerTextColor: "#ffffff",
        showDuration: true,
      }
    },
    {
      title: "AC",
      titleExtended: "AC (Acessórios)",
      extendedProps: {
        hasContent: true,
        hasFooter: true,
        bgColor: "yellow",
        headerTextColor: "#000000",
        footerTextColor: "#ffffff",
        showDuration: true,
      }
    },
    {
      title: "Almoço",
      titleExtended: "Almoço",
      extendedProps: {
        hasContent: false,
        hasFooter: false,
        bgColor: "#ff0000",
        headerTextColor: "#ffffff",
        footerTextColor: "#ffffff",
        showDuration: true,
      }
    },
    {
      title: "Férias",
      titleExtended: "Férias",
      extendedProps: {
        hasContent: false,
        hasFooter: false,
        bgColor: "#ff0000",
        headerTextColor: "#ffffff",
        footerTextColor: "#ffffff",
        showDuration: false,
      }
    },
    {
      title: "Licença Médica",
      titleExtended: "Licença Médica",
      extendedProps: {
        hasContent: false,
        hasFooter: false,
        bgColor: "#ff0000",
        headerTextColor: "#ffffff",
        footerTextColor: "#ffffff",
        showDuration: false,
      }
    },
    {
      title: "Outros",
      titleExtended: "Outros",
      extendedProps: {
        hasContent: false,
        hasFooter: false,
        bgColor: "#ff0000",
        headerTextColor: "#ffffff",
        footerTextColor: "#ffffff",
        showDuration: true,
      }
    },
  ],
};

export const enums = {
  status: [
    // 1 Programado
    // 2 Iniciou
    // 3 Finalizou
    // 6 Finalizou Qualidade
    // 4 Entregou Cliente
    // 5 Paralisado
    
    { value: 1, label: "Programado"},
    { value: 2, label: "Serviço iniciado"},
    { value: 3, label: "Finalizado"},
    { value: 6, label: "Qualidade finalizada"},
    { value: 4, label: "Entregue ao cliente"},
    { value: 5, label: "Paralisado"}
  ]
}

export default { config, enums }
